var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"1000","transition":"dialog-bottom-transition","scrollable":""},model:{value:(_vm.dialogRegistro),callback:function ($$v) {_vm.dialogRegistro=$$v},expression:"dialogRegistro"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" Registro de proyecto "),_c('v-spacer'),_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","disabled":_vm.btnRegistroLoading},on:{"click":function($event){_vm.dialogRegistro = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pl-0 pr-0"},[_c('v-tabs',{attrs:{"background-color":"blue","centered":"","dark":"","icons-and-text":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_c('v-tab',{attrs:{"href":"#tab-1"}},[_vm._v(" Datos principales "),_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")])],1),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v(" Información Adicional "),_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-tab',{attrs:{"href":"#tab-3"}},[_vm._v(" Cobertura "),_c('v-icon',[_vm._v("mdi-map-outline")])],1)],1),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.registrarItem.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":'tab-1'}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-1",attrs:{"md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"outlined":"","autocomplete":"off","dense":"","label":"Nombre del proyecto","rules":[
                            _vm.required('Nombre'),
                            _vm.minLength('Nombre', 5),
                            _vm.alphaRule('Nombre')
                          ],"maxlength":"50"},model:{value:(_vm.datosItem.nombreProyecto),callback:function ($$v) {_vm.$set(_vm.datosItem, "nombreProyecto", $$v)},expression:"datosItem.nombreProyecto"}})],1),_c('v-col',{staticClass:"pt-1 pb-1d",attrs:{"md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"outlined":"","autocomplete":"off","dense":"","label":"Descripción del proyecto","rules":[
                            _vm.required('Nombre'),
                            _vm.minLength('Nombre', 5),
                            _vm.alphaRule('Nombre')
                          ],"rows":"4","maxlength":"150"},model:{value:(_vm.datosItem.descripcionProyecto),callback:function ($$v) {_vm.$set(_vm.datosItem, "descripcionProyecto", $$v)},expression:"datosItem.descripcionProyecto"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-1",attrs:{"cols":"6","md":"6","sm":"12"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto","nudge-right":40},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","autocomplete":"off","rules":[_vm.required('Fecha de opinión técnica')],"label":"Fecha de solicitud de la opinión técnica","append-icon":"mdi-calendar","readonly":"","hint":"DD-MM-YYYY","clearable":""},model:{value:(_vm.fechaOpinionTecnica),callback:function ($$v) {_vm.fechaOpinionTecnica=$$v},expression:"fechaOpinionTecnica"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaOpinion),callback:function ($$v) {_vm.menuFechaOpinion=$$v},expression:"menuFechaOpinion"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker,"locale":"es","max":_vm.maxDate(),"min":_vm.minDate()},on:{"change":function($event){_vm.menuFechaOpinion = false},"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event}},model:{value:(_vm.fechas.fechaOpinion),callback:function ($$v) {_vm.$set(_vm.fechas, "fechaOpinion", $$v)},expression:"fechas.fechaOpinion"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-1",attrs:{"cols":"6","md":"6","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.unidadesEjecutoras,"loading":false,"dense":"","outlined":"","label":"Unidad Ejecutora","item-text":"nombre","item-value":"id","return-object":"","no-data-text":_vm.unidadesEjecutoras != null
                              ? 'Selecciona una unidad ejecutora'
                              : 'No se han encontrado unidades ejecutoras',"menu-props":"auto"},model:{value:(_vm.datosItem.unidadEjecutoraId),callback:function ($$v) {_vm.$set(_vm.datosItem, "unidadEjecutoraId", $$v)},expression:"datosItem.unidadEjecutoraId"}})],1),_c('v-col',{staticClass:"pt-1 pb-1",attrs:{"cols":"6","md":"6","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.unidadesEjecutoras,"loading":false,"dense":"","outlined":"","label":"Institución","item-text":"nombre","item-value":"id","return-object":"","no-data-text":_vm.unidadesEjecutoras != null
                              ? 'Selecciona una unidad ejecutora'
                              : 'No se han encontrado unidades ejecutoras',"menu-props":"auto"},model:{value:(_vm.datosItem.unidadEjecutoraId),callback:function ($$v) {_vm.$set(_vm.datosItem, "unidadEjecutoraId", $$v)},expression:"datosItem.unidadEjecutoraId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-1",attrs:{"cols":"12","md":"12","sm":"12"}},[[_c('v-file-input',{ref:"respaldoFile",staticClass:"required",attrs:{"id":"respaldoFile","dense":"","outlined":"","placeholder":"Seleccionar pdf de DPI","label":"Documento que avala la opinión técnica","append-icon":"mdi-file-pdf-outline","prepend-icon":"","accept":"application/pdf,image/jpeg","rules":[_vm.fileRequired('Documento de respaldo')],"show-size":1000},on:{"change":_vm.onFileChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                              var index = ref.index;
                              var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" archivo(s) ")]):_vm._e()]}}])})]],2)],1)],1)],1)],1)],1),_c('v-tab-item',{attrs:{"value":'tab-2'}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pt-2 pb-1d",attrs:{"md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"outlined":"","autocomplete":"off","dense":"","label":"Objetivo general del proyecto","rules":[
                            _vm.required('Objetivo'),
                            _vm.minLength('Objetivo', 25)
                          ],"rows":"6","maxlength":"150"},model:{value:(_vm.datosItem.objetivoProyecto),callback:function ($$v) {_vm.$set(_vm.datosItem, "objetivoProyecto", $$v)},expression:"datosItem.objetivoProyecto"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-1d",attrs:{"md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"outlined":"","autocomplete":"off","dense":"","label":"Objetivo específico 1","rules":[
                            _vm.required('Objetivo'),
                            _vm.minLength('Objetivo', 25)
                          ],"rows":"4","maxlength":"150"},model:{value:(_vm.datosItem.objetivoEspecifico1),callback:function ($$v) {_vm.$set(_vm.datosItem, "objetivoEspecifico1", $$v)},expression:"datosItem.objetivoEspecifico1"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-1d",attrs:{"md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"outlined":"","autocomplete":"off","dense":"","label":"Objetivo Específico 2","rules":[
                            _vm.required('Objetivo'),
                            _vm.minLength('Objetivo', 25)
                          ],"rows":"4","maxlength":"150"},model:{value:(_vm.datosItem.objetivoEspecifico2),callback:function ($$v) {_vm.$set(_vm.datosItem, "objetivoEspecifico2", $$v)},expression:"datosItem.objetivoEspecifico2"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-1d",attrs:{"md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"outlined":"","autocomplete":"off","dense":"","label":"Objetivo Específico 3","rules":[
                            _vm.required('Objetivo'),
                            _vm.minLength('Objetivo', 25)
                          ],"rows":"4","maxlength":"150"},model:{value:(_vm.datosItem.objetivoEspecifico3),callback:function ($$v) {_vm.$set(_vm.datosItem, "objetivoEspecifico3", $$v)},expression:"datosItem.objetivoEspecifico3"}})],1)],1)],1)],1)],1)],1),_c('v-tab-item',{attrs:{"value":'tab-3'}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-1",attrs:{"cols":"6","md":"6","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.departamentos,"loading":false,"dense":"","outlined":"","label":"Departamento","item-text":"nombre","item-value":"id","return-object":"","no-data-text":_vm.unidadesEjecutoras != null
                              ? 'Selecciona una unidad ejecutora'
                              : 'No se han encontrado unidades ejecutoras',"menu-props":"auto"},model:{value:(_vm.departamentoSeleccionado),callback:function ($$v) {_vm.departamentoSeleccionado=$$v},expression:"departamentoSeleccionado"}})],1),_c('v-col',{staticClass:"pt-1 pb-1",attrs:{"cols":"6","md":"6","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.municipios,"loading":false,"dense":"","outlined":"","label":"Municipio","item-text":"nombre","item-value":"id","return-object":"","no-data-text":_vm.unidadesEjecutoras != null
                              ? 'Selecciona una unidad ejecutora'
                              : 'No se han encontrado unidades ejecutoras',"menu-props":"auto"},model:{value:(_vm.municipioSeleccionado),callback:function ($$v) {_vm.municipioSeleccionado=$$v},expression:"municipioSeleccionado"}})],1),_c('v-col',{attrs:{"sm":"12"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"medium":"","tile":"","outlined":"","color":"indigo"},on:{"click":_vm.agregarCobertura}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Agregar ")],1)],1),_c('v-col',{attrs:{"sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersCobertura,"items":_vm.cobertura,"footer-props":{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            'items-per-page-text': 'Registros por página',
                            pageText: '{0}-{1} de {2}'
                          }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                          var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.departamento))]),_c('td',[_vm._v(_vm._s(item.municipio))]),_c('td',[_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","tile":"","outlined":"","color":"indigo"},on:{"click":function($event){return _vm.eliminarCobertura(item.municipio)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Eliminar ")],1)],1)])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 float-right grey lighten-5",attrs:{"color":"grey lighten-5","elevation":"0","disabled":_vm.btnRegistroLoading},on:{"click":function($event){_vm.dialogRegistro = false;
            _vm.resetForm();}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"mb-2 float-right",attrs:{"color":"primary","type":"submit","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroLoading}},[_vm._v(" Guardar ")])],1),_c('div',{staticStyle:{"flex":"1 1 auto"}})],1)],1),_c('SnackAlert',{ref:"snackalert"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }