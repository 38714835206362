<template>
  <div>
    <!--begin:: dialog registro-->
    <v-dialog
      v-model="dialogRegistro"
      max-width="1000"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-card-title>
          Registro de proyecto

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogRegistro = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pl-0 pr-0">
          <v-tabs
            v-model="tab"
            background-color="blue"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              Datos principales
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-tab>

            <v-tab href="#tab-2">
              Información Adicional
              <v-icon>mdi-plus</v-icon>
            </v-tab>

            <v-tab href="#tab-3">
              Cobertura
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>
          </v-tabs>
          <v-form
            ref="form"
            v-on:submit.prevent="registrarItem"
            v-model="validForm"
          >
            <v-tabs-items v-model="tab">
              <v-tab-item :value="'tab-1'">
                <v-card flat>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col md="12" sm="12" class="pt-1 pb-1">
                          <v-text-field
                            outlined
                            autocomplete="off"
                            class="required"
                            dense
                            v-model="datosItem.nombreProyecto"
                            label="Nombre del proyecto"
                            :rules="[
                              required('Nombre'),
                              minLength('Nombre', 5),
                              alphaRule('Nombre')
                            ]"
                            maxlength="50"
                          ></v-text-field>
                        </v-col>

                        <v-col md="12" sm="12" class="pt-1 pb-1d">
                          <v-textarea
                            outlined
                            autocomplete="off"
                            class="required"
                            dense
                            v-model="datosItem.descripcionProyecto"
                            label="Descripción del proyecto"
                            :rules="[
                              required('Nombre'),
                              minLength('Nombre', 5),
                              alphaRule('Nombre')
                            ]"
                            rows="4"
                            maxlength="150"
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6" md="6" sm="12" class="pt-1 pb-1">
                          <v-menu
                            ref="menu1"
                            v-model="menuFechaOpinion"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                            :nudge-right="40"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                dense
                                outlined
                                autocomplete="off"
                                :rules="[required('Fecha de opinión técnica')]"
                                v-model="fechaOpinionTecnica"
                                label="Fecha de solicitud de la opinión técnica"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hint="DD-MM-YYYY"
                                class=""
                                clearable
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="fechas.fechaOpinion"
                              @change="menuFechaOpinion = false"
                              :active-picker.sync="activePicker"
                              locale="es"
                              :max="maxDate()"
                              :min="minDate()"
                            ></v-date-picker>
                            <!--:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"-->
                          </v-menu>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6" md="6" sm="12" class="pt-1 pb-1">
                          <v-select
                            v-model="datosItem.unidadEjecutoraId"
                            :items="unidadesEjecutoras"
                            :loading="false"
                            dense
                            outlined
                            label="Unidad Ejecutora"
                            item-text="nombre"
                            item-value="id"
                            return-object
                            :no-data-text="
                              unidadesEjecutoras != null
                                ? 'Selecciona una unidad ejecutora'
                                : 'No se han encontrado unidades ejecutoras'
                            "
                            menu-props="auto"
                          ></v-select>
                        </v-col>

                        <v-col cols="6" md="6" sm="12" class="pt-1 pb-1">
                          <v-select
                            v-model="datosItem.unidadEjecutoraId"
                            :items="unidadesEjecutoras"
                            :loading="false"
                            dense
                            outlined
                            label="Institución"
                            item-text="nombre"
                            item-value="id"
                            return-object
                            :no-data-text="
                              unidadesEjecutoras != null
                                ? 'Selecciona una unidad ejecutora'
                                : 'No se han encontrado unidades ejecutoras'
                            "
                            menu-props="auto"
                          ></v-select>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="12" sm="12" class="pt-1 pb-1">
                          <template>
                            <v-file-input
                              id="respaldoFile"
                              dense
                              outlined
                              placeholder="Seleccionar pdf de DPI"
                              label="Documento que avala la opinión técnica"
                              append-icon="mdi-file-pdf-outline"
                              prepend-icon=""
                              accept="application/pdf,image/jpeg"
                              @change="onFileChange"
                              :rules="[fileRequired('Documento de respaldo')]"
                              :show-size="1000"
                              ref="respaldoFile"
                              class="required"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  small
                                  label
                                  color="primary"
                                >
                                  {{ text }}
                                </v-chip>
                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} archivo(s)
                                </span>
                              </template>
                            </v-file-input>
                          </template>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item :value="'tab-2'">
                <v-card flat>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col md="12" sm="12" class="pt-2 pb-1d">
                          <v-textarea
                            outlined
                            autocomplete="off"
                            class="required"
                            dense
                            v-model="datosItem.objetivoProyecto"
                            label="Objetivo general del proyecto"
                            :rules="[
                              required('Objetivo'),
                              minLength('Objetivo', 25)
                            ]"
                            rows="6"
                            maxlength="150"
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col md="12" sm="12" class="pt-0 pb-1d">
                          <v-textarea
                            outlined
                            autocomplete="off"
                            class="required"
                            dense
                            v-model="datosItem.objetivoEspecifico1"
                            label="Objetivo específico 1"
                            :rules="[
                              required('Objetivo'),
                              minLength('Objetivo', 25)
                            ]"
                            rows="4"
                            maxlength="150"
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col md="12" sm="12" class="pt-0 pb-1d">
                          <v-textarea
                            outlined
                            autocomplete="off"
                            class="required"
                            dense
                            v-model="datosItem.objetivoEspecifico2"
                            label="Objetivo Específico 2"
                            :rules="[
                              required('Objetivo'),
                              minLength('Objetivo', 25)
                            ]"
                            rows="4"
                            maxlength="150"
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col md="12" sm="12" class="pt-0 pb-1d">
                          <v-textarea
                            outlined
                            autocomplete="off"
                            class="required"
                            dense
                            v-model="datosItem.objetivoEspecifico3"
                            label="Objetivo Específico 3"
                            :rules="[
                              required('Objetivo'),
                              minLength('Objetivo', 25)
                            ]"
                            rows="4"
                            maxlength="150"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item :value="'tab-3'">
                <v-card flat>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6" md="6" sm="12" class="pt-1 pb-1">
                          <v-select
                            v-model="departamentoSeleccionado"
                            :items="departamentos"
                            :loading="false"
                            dense
                            outlined
                            label="Departamento"
                            item-text="nombre"
                            item-value="id"
                            return-object
                            :no-data-text="
                              unidadesEjecutoras != null
                                ? 'Selecciona una unidad ejecutora'
                                : 'No se han encontrado unidades ejecutoras'
                            "
                            menu-props="auto"
                          ></v-select>
                        </v-col>

                        <v-col cols="6" md="6" sm="12" class="pt-1 pb-1">
                          <v-select
                            v-model="municipioSeleccionado"
                            :items="municipios"
                            :loading="false"
                            dense
                            outlined
                            label="Municipio"
                            item-text="nombre"
                            item-value="id"
                            return-object
                            :no-data-text="
                              unidadesEjecutoras != null
                                ? 'Selecciona una unidad ejecutora'
                                : 'No se han encontrado unidades ejecutoras'
                            "
                            menu-props="auto"
                          ></v-select>
                        </v-col>

                        <v-col sm="12">
                          <v-btn
                            class="ma-2"
                            medium
                            tile
                            outlined
                            color="indigo"
                            @click="agregarCobertura"
                          >
                            <v-icon left>mdi-plus</v-icon> Agregar
                          </v-btn>
                        </v-col>

                        <v-col sm="12">
                          <v-data-table
                            class="elevation-1"
                            :headers="headersCobertura"
                            :items="cobertura"
                            :footer-props="{
                              showFirstLastPage: true,
                              firstIcon: 'mdi-page-first',
                              lastIcon: 'mdi-page-last',
                              prevIcon: 'mdi-chevron-left',
                              nextIcon: 'mdi-chevron-right',
                              'items-per-page-text': 'Registros por página',
                              pageText: '{0}-{1} de {2}'
                            }"
                          >
                            <template v-slot:item="{ item }">
                              <tr>
                                <td>{{ item.departamento }}</td>
                                <td>{{ item.municipio }}</td>

                                <td>
                                  <v-btn
                                    class="ma-2"
                                    small
                                    tile
                                    outlined
                                    color="indigo"
                                    @click="eliminarCobertura(item.municipio)"
                                  >
                                    <v-icon left>mdi-delete</v-icon> Eliminar
                                  </v-btn>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-5"
            elevation="0"
            class="mb-2 float-right grey lighten-5"
            @click="
              dialogRegistro = false;
              resetForm();
            "
            :disabled="btnRegistroLoading"
          >
            Cancelar
          </v-btn>
          <!--:disabled="!validDocForm" type="submit"-->
          <v-btn
            color="primary"
            class="mb-2 float-right"
            type="submit"
            :elevation="0"
            :disabled="!validForm"
            :loading="btnRegistroLoading"
          >
            Guardar
          </v-btn>
        </v-card-actions>

        <div style="flex: 1 1 auto;"></div>
      </v-card>
    </v-dialog>
    <!--end:: dialog registro-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>

<script>
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";

import moment from "moment";
import validations from "@/core/untils/validations.js";

export default {
  name: "ModalRegistroProyectoSinOT",
  components: {
    SnackAlert
    // DialogLoader
  },
  data() {
    return {
      dialogRegistro: false,
      tab: null,
      validForm: false,
      btnRegistroLoading: false,
      menuFechaOpinion: false,
      unidadesEjecutoras: [],
      datosItem: {
        nombreProyecto: "",
        descripcionProyecto: "",
        fechaOpinionTecnica: "",
        unidadEjecutoraId: 0,
        objetivoProyecto: "",
        objetivoEspecifico1: "",
        objetivoEspecifico2: "",
        objetivoEspecifico3: ""
      },
      documentoOpinionTecnica: [],
      municipioSeleccionado: [],
      departamentoSeleccionado: [],
      activePicker: null,
      fechas: {
        fechaOpinion: ""
      },
      ...validations,
      cbxopt: [1, 2],
      radioGroup: 1,
      departamentos: [
        {
          id: 1,
          nombre: "Guatemala"
        },
        {
          id: 2,
          nombre: "Escuintla"
        },
        {
          id: 3,
          nombre: "Santa Rosa"
        },
        {
          id: 4,
          nombre: "Jutiapa"
        }
      ],
      municipios: [
        {
          id: 1,
          nombre: "Guatemala"
        },
        {
          id: 2,
          nombre: "Santa Catarina Pinula"
        },
        {
          id: 3,
          nombre: "San José Pinula"
        },
        {
          id: 4,
          nombre: "San José del Golfo"
        },
        {
          id: 5,
          nombre: "Palencia"
        },
        {
          id: 6,
          nombre: "Chinautla"
        },
        {
          id: 7,
          nombre: "San Pedro Ayampuc"
        }
      ],
      cobertura: [],

      headersCobertura: [
        {
          text: "Departamento",
          align: "start",
          sortable: false,
          value: "nombre"
        },
        {
          text: "Municipio",
          align: "start",
          sortable: true,
          value: "fecha"
        },
        {
          text: "",
          align: "start",
          sortable: true,
          value: "nombre"
        }
      ]
    };
  },
  methods: {
    //!Definir la fecha máxima que se puede seleccionar en el datepicker
    maxDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      return date.toISOString().substr(0, 10);
    },

    //!Definir la fecha minima que se puede seleccionar en el datepicker
    minDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear());
      return date.toISOString().substr(0, 10);
    },

    onFileChange(e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "application/pdf" && e.type != "image/jpeg") {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 5Mb
        if (e.size / 1024 / 1024 > 3) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (3Mb)`
          );
          // this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }

        this.documentoOpinionTecnica = e;
      }
    },

    obtenerUnidadesEjecutoras() {
      this.unidadesEjecutoras = [
        {
          id: 1,
          nombre: "Unidad Ejecutora 1"
        },
        {
          id: 2,
          nombre: "Unidad Ejecutora 2"
        },
        {
          id: 3,
          nombre: "Unidad Ejecutora 3"
        },
        {
          id: 4,
          nombre: "Unidad Ejecutora 4"
        },
        {
          id: 5,
          nombre: "Unidad Ejecutora 5"
        }
      ];
    },

    mostrarModal() {
      this.dialogRegistro = true;
    },
    ocultarModal() {
      //console.log("ocultando");
      this.dialogRegistro = false;
    },

    agregarCobertura() {
      //Buscar el municipio que se quiere Ingresar

      var encontrado = this.cobertura.find(
        e => e.municipioId === this.municipioSeleccionado.id
      );

    //  console.log(encontrado);

      if (encontrado) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `El municipio que intenta agregar ya se encuentra registrado`
        );
        return;
      }

      this.cobertura.push({
        departamento: this.departamentoSeleccionado.nombre,
        municipio: this.municipioSeleccionado.nombre,
        municipioId: this.municipioSeleccionado.id
      });
    },

    eliminarCobertura(municipio) {
      this.cobertura.splice(
        this.cobertura.findIndex(e => e.municipio == municipio),
        1
      );
    },

    registrarItem() {
     // console.log("registrando....");
    },

    resetForm() {}
  },
  computed: {
    //Cambiar el formato de fechas a DD-MM-YYY para mostrar y a YYYY-MM-DD para registrar en datosItem.fechaApertura
    fechaOpinionTecnica: {
      get: function() {
        return this.fechas.fechaOpinion
          ? moment(this.fechas.fechaOpinion).format("DD-MM-YYYY")
          : null;
      },
      set: function(val) {
        this.datosItem.fechaOpinionTecnica = moment(val, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        );
        return val;
      }
    }
  },
  created() {
    this.obtenerUnidadesEjecutoras();
  }
};
</script>
